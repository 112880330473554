<script setup>
import SoldesUsager from '@/components/usager/finances/SoldesUsager.vue'
import ListeMouvementsUsager from '@/components/usager/finances/ListeMouvementsUsager.vue'
import ListeFicheAimUsager from '@/components/usager/aim/ListeFicheAimUsager.vue'
</script>

<template>
  <v-container class="h-100">
    <v-row class="h-100" no-gutters>
      <v-col cols="4" class="pr-2">
        <soldes-usager
          :usager-id="usagerId"
          @input="selectOrUnselectPosteAim"
          :selected-poste-aim-id="posteAimIdSelectionne"
        />
      </v-col>
      <v-col cols="4" class="px-2">
        <liste-fiche-aim-usager :usager-id="usagerId" :selected-poste-aim-id="posteAimIdSelectionne" />
      </v-col>
      <v-col cols="4" class="pl-2">
        <liste-mouvements-usager :usager-id="usagerId" :selected-poste-aim-id="posteAimIdSelectionne" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'historique-accueil-usager',
  data() {
    return {
      posteAimIdSelectionne: '',
    }
  },
  computed: {
    usagerId() {
      return parseInt(this.$route.params.usagerId)
    },
  },
  methods: {
    selectOrUnselectPosteAim(newPosteAimIdSelectionne) {
      if (newPosteAimIdSelectionne === this.posteAimIdSelectionne) {
        this.posteAimIdSelectionne = ''
      } else {
        this.posteAimIdSelectionne = newPosteAimIdSelectionne
      }
    },
  },
}
</script>

<style scoped></style>
