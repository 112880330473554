<script setup>
import './SoldesUsager.vue'
import CarteSoldeUsager from '@/components/usager/finances/CarteSoldeUsager.vue'
import SMessageChargement from '@/components/global/s-message-chargement.vue'
import SCardWithToolbar from '@/components/global/s-card-with-toolbar.vue'
import SInfoMontant from '@/components/global/s-info-montant.vue'

defineEmits(['input'])
</script>

<template>
  <s-card-with-toolbar class="fixed-height" :elevation="0">
    <template #titre>
      <v-toolbar-title class="text-h6">Soldes par poste AIM</v-toolbar-title>
    </template>
    <v-container class="fixed-height pa-0">
      <perfect-scrollbar v-if="!loading">
        <v-row no-gutters>
          <v-col class="my-2 px-1" sm="12" md="12" lg="6" v-for="solde in soldesUsager" :key="solde.poste">
            <carte-solde-usager
              :solde="solde"
              :is-selected="solde.poste === selectedPosteAimId"
              @click="$emit('input', solde.poste)"
            />
          </v-col>
        </v-row>
      </perfect-scrollbar>
      <s-message-chargement class="ma-6" v-else>Chargement des soldes</s-message-chargement>
    </v-container>
    <template #bottom>
      <v-container>
        <v-row class="mx-4 my-1" justify="end" align-content="end">
          <p class="mb-0 mr-2">Montant total :</p>
          <s-info-montant :montant="totalPostesAIM"></s-info-montant>
        </v-row>
      </v-container>
    </template>
  </s-card-with-toolbar>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'soldes-usager',
  props: {
    usagerId: {
      type: Number,
      required: true,
    },
    selectedPosteAimId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      soldesUsager: undefined,
    }
  },
  computed: {
    ...mapGetters({
      selectedEtablissement: 'application/getSelectedEtablissement',
    }),
    totalPostesAIM() {
      if (this.soldesUsager === undefined) return 0.0
      let tt = 0.0
      this.soldesUsager.forEach((poste) => (tt += poste.montant))
      return tt
    },
    search() {
      return {
        usagerId: this.usagerId,
        etablissementId: this.selectedEtablissement.etablissementId,
      }
    },
  },
  methods: {
    ...mapActions({
      obtenirSoldesUsagerDansEtablissement: 'usager/obtenirSoldesUsagerDansEtablissement',
      fetchPostes: 'posteAIM/fetchPostes',
    }),
  },
  mounted() {
    this.obtenirSoldesUsagerDansEtablissement(this.search)
      .then((value) => {
        this.soldesUsager = value
      })
      .then(this.fetchPostes())
      .then((this.loading = false))
  },
}
</script>
<style scoped>
.fixed-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}
</style>
