<script setup>
import SCardWithToolbar from '@/components/global/s-card-with-toolbar.vue'
import FormCreationAjustement from '@/components/fiche-aim/preparationFicheAim/ajustement/form-operations-ajustement.vue'
import AjustementListItem from '@/components/fiche-aim/preparationFicheAim/ajustement/ajustement-list-item.vue'
</script>

<template ref="ajustementVue">
  <s-card-with-toolbar :loading="loading">
    <template #titre>Ajustements</template>
    <template #default>
      <div class="h-100">
        <v-scroll-x-transition>
          <v-row no-gutters v-if="etat === 'LISTE_AJUSTEMENT'" class="h-100">
            <v-col cols="12" class="h-100">
              <perfect-scrollbar class="h-100">
                <v-list>
                  <ajustement-list-item
                    :read-only="readOnly"
                    v-for="ajustement in ajustements"
                    :key="ajustement.ajustementId"
                    :ajustement="ajustement"
                    @edit="setupEditionAjustement(ajustement)"
                    @delete="envoyerCommandeRetirerAjustement(ajustement.ajustementId)"
                  />
                </v-list>
              </perfect-scrollbar>
            </v-col>
          </v-row>
        </v-scroll-x-transition>
        <v-scroll-x-transition>
          <v-container v-if="etat === 'MODIFICATION_AJUSTEMENT' || etat === 'CREATION_AJUSTEMENT'">
            <v-container class="context-box">
              <h4 class="text-decoration-none">Contexte</h4>
              <v-textarea
                class="textarea"
                label="Justification"
                v-model="commandeAjustement.explication"
                required
              ></v-textarea>
            </v-container>
            <v-container class="operations-box">
              <form-creation-ajustement class="mt-8" :ajustement="commandeAjustement"></form-creation-ajustement>
            </v-container>
          </v-container>
        </v-scroll-x-transition>
      </div>
    </template>
    <template #bottom>
      <v-card>
        <v-card-actions v-if="etat === 'LISTE_AJUSTEMENT'">
          <v-spacer />
          <v-btn color="primary" @click="setupCreationEpargne">
            <v-icon class="pr-2">mdi-piggy-bank</v-icon>
            Épargner
          </v-btn>
          <v-btn color="primary" @click="setupCreationDette">
            <v-icon class="pr-2">mdi-hand-coin</v-icon>
            Créer une dette
          </v-btn>
          <v-btn color="primary" @click="setupCreationAjustement">
            <v-icon class="pr-2">mdi-plus</v-icon>
            Créer un ajustement
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="etat === 'CREATION_AJUSTEMENT'">
          <v-spacer />
          <v-btn class="mx-1" color="grey" @click="fallbackToDefaultState"> Annuler </v-btn>
          <v-btn
            class="mx-1"
            :disabled="!estValideAjustementEnCreation"
            color="primary"
            @click="envoyerCommandeAjusterFiche"
          >
            Confirmer
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="etat === 'MODIFICATION_AJUSTEMENT'">
          <v-spacer />
          <v-btn class="mx-1" color="grey" @click="fallbackToDefaultState"> Annuler </v-btn>
          <v-btn class="mx-1" :disabled="!estValideAjustementEnCreation" color="primary" @click="modifierAjustement">
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </s-card-with-toolbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ajustements-aim',
  data() {
    return {
      etat: 'LISTE_AJUSTEMENT',
      commandeAjustement: {},
      loading: false,
    }
  },
  props: {
    ajustements: {
      type: Array,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getPosteById: 'posteAIM/findPosteById',
    }),
    posteEpargne() {
      return this.getPosteById('EPARGNE')
    },
    posteDette() {
      return this.getPosteById('DETTE')
    },
    ficheAimId() {
      return parseInt(this.$route.params.ficheAimId, 10)
    },
    nbMotsExplication() {
      return this.commandeAjustement.explication.trim().split(' ').length
    },
    estValideAjustementEnCreation() {
      return this.nbMotsExplication >= 5 && Object.values(this.commandeAjustement.operations).length > 0
    },
  },
  methods: {
    ...mapActions({
      fetchPostes: 'posteAIM/fetchPostes',
      ajusterFicheAIM: 'ficheAIM/ajusterFicheAIM',
      retirerAjustement: 'ficheAIM/retirerAjustement',
    }),
    formaterCommandeAjusterFiche() {
      let ops = []
      this.commandeAjustement.operations.forEach((op) =>
        ops.push({
          posteAIMId: op.posteAIM.posteAIMId,
          montant: op.montant,
        })
      )
      this.commandeAjustement = {
        ficheAIMId: this.ficheAimId,
        operations: ops,
        explication: this.commandeAjustement.explication,
      }
    },
    envoyerCommandeAjusterFiche() {
      this.loading = true
      this.formaterCommandeAjusterFiche()
      this.ajusterFicheAIM(this.commandeAjustement).then(() => this.fallbackToDefaultState())
    },
    envoyerCommandeRetirerAjustement(ajustementId) {
      this.loading = true
      this.retirerAjustement({
        ficheAIMId: this.ficheAimId,
        ajustementId: ajustementId,
      }).then(() => this.fallbackToDefaultState())
    },
    modifierAjustement() {
      this.loading = true
      this.retirerAjustement({
        ficheAIMId: this.ficheAimId,
        ajustementId: this.commandeAjustement.ajustementId,
      }).then(() => this.envoyerCommandeAjusterFiche())
    },
    razCommandeAjustement() {
      this.commandeAjustement = {
        ficheAIMId: this.ficheAimId,
        operations: [],
        explication: '',
      }
    },
    razCommandeAjustementPourEpargne() {
      this.commandeAjustement = {
        ficheAIMId: this.ficheAimId,
        operations: [
          {
            montant: 0,
            posteAIM: this.posteEpargne,
          },
        ],
        explication: 'Epargne : ',
      }
    },
    razCommandeAjustementPourDette() {
      this.commandeAjustement = {
        ficheAIMId: this.ficheAimId,
        operations: [
          {
            montant: 0,
            posteAIM: this.posteDette,
          },
        ],
        explication: 'Dette : ',
      }
    },
    setupCreationAjustement() {
      this.razCommandeAjustement()
      this.etat = 'CREATION_AJUSTEMENT'
    },
    setupCreationEpargne() {
      this.razCommandeAjustementPourEpargne()
      this.etat = 'CREATION_AJUSTEMENT'
    },
    setupCreationDette() {
      this.razCommandeAjustementPourDette()
      this.etat = 'CREATION_AJUSTEMENT'
    },
    fallbackToDefaultState() {
      this.loading = false
      this.razCommandeAjustement()
      this.etat = 'LISTE_AJUSTEMENT'
    },
    setupEditionAjustement(ajustementAEditer) {
      this.commandeAjustement = ajustementAEditer
      this.etat = 'MODIFICATION_AJUSTEMENT'
    },
  },
  created() {
    this.fetchPostes()
  },
}
</script>

<style>
.fixed-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}
textarea {
  height: 100px !important;
}

.context-box {
  height: 16vh !important;
}
.operations-box {
  height: 32vh !important;
}
</style>
