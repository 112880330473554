import { render, staticRenderFns } from "./employe-detail.vue?vue&type=template&id=473ac8e3&scoped=true"
import script from "./employe-detail.vue?vue&type=script&lang=js"
export * from "./employe-detail.vue?vue&type=script&lang=js"
import style0 from "./employe-detail.vue?vue&type=style&index=0&id=473ac8e3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "473ac8e3",
  null
  
)

export default component.exports