<script setup>
import AjustementsAim from '@/components/fiche-aim/preparationFicheAim/ajustements-aim.vue'
import RenduFicheAim from '@/components/fiche-aim/preparationFicheAim/rendu-fiche-aim.vue'
import VersementsAim from '@/components/fiche-aim/preparationFicheAim/versements-aim.vue'
import SStepCardPanels from '@/components/global/step-card-pannel/s-step-card-panels.vue'
</script>

<template>
  <s-step-card-panels
    v-if="!loading && ficheAim !== undefined"
    titre-premier-panel="Préparation des ajustement"
    titre-second-panel="Préparation des versements"
    :sousTitre="sousTitreFicheAIM"
  >
    <template #first-panel="{ onStepNext }">
      <ajustements-aim
        class="fixed-height"
        @next-step="onStepNext"
        :ajustements="ficheAim.ajustements"
      ></ajustements-aim>
    </template>
    <template #second-panel>
      <rendu-fiche-aim class="fixed-height" :fiche-aim="ficheAim"></rendu-fiche-aim>
    </template>
    <template #third-panel="{ onStepPrev }">
      <versements-aim class="fixed-height" :prev-step="onStepPrev"></versements-aim>
    </template>
  </s-step-card-panels>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'preparation-fiche-aim',
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      getficheById: 'ficheAIM/getFicheAimById',
      getMonthLabel: 'calendrier/getMonthLabel',
    }),
    ficheAimId() {
      return parseInt(this.$route.params.ficheAimId)
    },
    ficheAim() {
      return this.getficheById(this.ficheAimId)
    },
    nomComplet() {
      return this.ficheAim.usager.nom + ' ' + this.ficheAim.usager.prenom
    },
    sousTitreFicheAIM() {
      return this.nomComplet + ' - ' + this.getMonthLabel(this.ficheAim.mois) + ' ' + this.ficheAim.annee
    },
  },

  methods: {
    ...mapActions({
      fetchFicheAimById: 'ficheAIM/fetchFicheAimByFicheId',
    }),
  },
  beforeMount() {
    this.fetchFicheAimById(this.ficheAimId).then(() => (this.loading = false))
  },
}
</script>
