<template>
  <v-dialog v-model="showDialog" persistent max-width="800px">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-icon>
          <v-icon color="error">mdi-clock-minus</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Débiter des heures</v-list-item-title>
      </v-list-item>
    </template>
    <v-card v-if="showDialog">
      <s-toolbar text="Débiter des heures" create @close="close()" />
      <v-card-text>
        <heures-debit-form v-model="ajouterHeuresDebitCommand" create @updateFormValid="updateFormValid" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey darken-1" text @click="close()">Annuler</v-btn>
        <v-btn color="success" :disabled="!formValid" @click="validate()"> Confirmer </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import HeuresDebitForm from '@/components/employe/soldeDeRecuperation/debit/heures-debit-form.vue'
import SToolbar from '@/components/global/s-toolbar.vue'

export default {
  name: 'heures-debit-create-dialog',
  components: {
    SToolbar,
    HeuresDebitForm,
  },
  props: {
    employe: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      showDialog: false,
      formValid: false,
      ajouterHeuresDebitCommand: undefined,
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    ...mapActions({
      ajouterHeuresDebit: 'employe/ajouterHeuresCredit',
    }),
    initialize() {
      this.ajouterHeuresDebitCommand = {
        employeId: this.employe ? this.employe.employeId : undefined,
        dateDapplication: undefined,
        nbHeures: undefined,
        raison: true,
        commentaire: undefined,
      }
    },
    updateFormValid(_validity) {
      this.formValid = _validity
    },
    validate() {
      if (this.formValid) {
        this.ajouterHeuresDebit(this.ajouterHeuresDebitCommand)
        this.close()
      }
    },
    close() {
      this.initialize()
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>
