<template>
  <v-list dense nav class="text-body-1 py-0">
    <v-subheader v-if="displayEtablissement && etablissement" v-text="etablissement.raisonSociale" />
    <v-list-item-group
      v-model="selectedCongesSolde"
      color="primary"
      :value="selectedCongesSolde"
      :value-comparator="valueCompare"
    >
      <v-list-item
        v-for="(type, index) in congesTypesAvecSolde"
        :key="index"
        :value="{ congesTypeId: type.congesTypeId, employeId: employeId }"
      >
        <v-list-item-content class="py-1">
          <v-list-item-title justify="center" v-text="type.label" />
        </v-list-item-content>
        <v-list-item-action class="ma-0">
          <conges-solde-chip :valeur="getSoldeByCongesTypeId(type.congesTypeId)" />
        </v-list-item-action>
      </v-list-item>
      <v-list-item :value="{ congesTypeId: 0, employeId: employeId }">
        <v-list-item-content class="py-1">
          <v-list-item-title justify="center" v-text="'Autres congés'" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item :value="{ congesTypeId: -1, employeId: employeId }">
        <v-list-item-content class="py-1">
          <v-list-item-title justify="center" v-text="'Récupérations'" />
        </v-list-item-content>
        <v-list-item-action class="ma-0 flex-row align-center">
          <div class="flex-column align-center ma-2 mr-4">
            <div class="text-center">À date</div>
            <conges-solde-chip :valeur="soldeDeRecuperationADate" unite="h" />
          </div>
          <div class="flex-column align-center justify-center">
            <div class="text-center">Total</div>
            <conges-solde-chip :valeur="soldeDeRecuperationTotal" unite="h" />
          </div>
        </v-list-item-action>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import CongesSoldeChip from '../conges/solde/conges-solde-chip'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'conges-solde-list',
  components: { CongesSoldeChip },
  props: {
    value: {
      type: Object,
      required: false,
      default: undefined,
    },
    employeId: {
      type: Number,
      required: true,
    },
    displayEtablissement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedCongesSolde: undefined,
    }
  },
  computed: {
    ...mapGetters({
      getCongesTypes: 'conges/getCongesTypes',
      getEmployeById: 'employe/getEmployeById',
      getEtablissementById: 'etablissement/getEtablissementById',
      utilisateur: 'security/getUtilisateur',
    }),
    loaded() {
      return !!this.employe
    },
    congesTypesAvecSolde() {
      return this.getCongesTypes.filter(
        (t) =>
          t.avecSolde &&
          (this.employe.conges.filter((c) => c.type.congesTypeId === t.congesTypeId).length > 0 ||
            this.employe.congesCredits.filter((cc) => cc.type.congesTypeId === t.congesTypeId).length > 0)
      )
    },
    employe() {
      return this.getEmployeById(this.employeId)
    },
    etablissement() {
      if (this.employe) {
        return this.getEtablissementById(this.employe.etablissement.etablissementId)
      } else {
        return undefined
      }
    },
    soldeDeRecuperationADate() {
      if (this.employe.recuperations) {
        let result = this.employe.recuperations.soldeDeRecuperationADate / 60
        return Math.round(result * 100) / 100
      } else {
        return '-'
      }
    },
    soldeDeRecuperationTotal() {
      if (this.employe.recuperations) {
        let result = this.employe.recuperations.soldeDeRecuperationTotal / 60
        return Math.round(result * 100) / 100
      } else {
        return '-'
      }
    },
  },
  created() {
    this.selectedCongesSolde = this.value
  },
  watch: {
    employeId: {
      handler(employeId) {
        this.fetchRecuperationsByEmployeId(employeId)
      },
      immediate: true,
    },
    selectedCongesSolde() {
      this.$emit('input', this.selectedCongesSolde)
    },
    value() {
      this.selectedCongesSolde = this.value
    },
  },
  methods: {
    ...mapActions({
      fetchRecuperationsByEmployeId: 'employe/fetchRecuperationById',
    }),
    valueCompare(a, b) {
      return a && b && a.congesTypeId === b.congesTypeId && a.employeId === b.employeId
    },
    getSoldeByCongesTypeId(_congesTypeId) {
      return this.employe.congesSoldes.find((cs) => cs.congesType.congesTypeId === _congesTypeId).solde
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
