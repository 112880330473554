<script setup>
import SInfoMontant from '@/components/global/s-info-montant.vue'

const props = defineProps({
  mouvement: {
    type: Object,
    required: true,
  },
})
</script>

<template>
  <tbody>
    <tr>
      <td>
        {{ dateApplication }}
      </td>
      <td>
        <v-icon class="px-2" :color="iconColor">{{ iconePosteAim }}</v-icon>
      </td>
      <td>
        <s-info-montant class="text-left" colorisation :montant="props.mouvement.montantMensuel"></s-info-montant>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'mouvement-list-item',
  computed: {
    ...mapGetters({
      getIconePourPosteAIMId: 'posteAIM/getIconePourPosteAimId',
      dateFormater: 'calendrier/formatDateToDisplay',
    }),
    iconePosteAim() {
      return this.getIconePourPosteAIMId(this.mouvement.posteAim.posteAIMId)
    },
    dateApplication() {
      return this.dateFormater(this.mouvement.dateApplication)
    },
    iconColor() {
      if (this.mouvement.montantMensuel > 0) return 'success'
      if (this.mouvement.montantMensuel < 0) return 'error'
      return 'grey'
    },
  },
}
</script>

<style scoped></style>
