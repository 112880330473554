import axios from 'axios'

export default {
  namespaced: true,
  state: {
    usagers: [],
    mouvements: [],
  },
  mutations: {
    ADD_USAGER(state, _usager) {
      state.usagers.push(_usager)
    },
    SET_USAGERS(state, _usagers) {
      state.usagers = _usagers
    },
    SET_MOUVEMENTS(state, _mouvements) {
      state.mouvements = _mouvements
    },
    REMOVE_USAGER(state, _usagerId) {
      for (let i = 0; i < state.usagers.length; i++) {
        if (state.usagers[i].usagerId === _usagerId) {
          state.usagers.splice(i, 1)
          break
        }
      }
    },
  },
  getters: {
    getUsagers: (state) => {
      return state.usagers
    },
    getMouvements: (state) => {
      return state.mouvements
    },
    getUsagerById: (state) => (_usagerId) => {
      return state.usagers.find((usager) => usager.usagerId === _usagerId)
    },
  },
  actions: {
    fetchUsagers({ commit }) {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/usager`).then((response) => {
        commit('SET_USAGERS', response.data)
      })
    },
    fetchMouvementsBySearch({ commit }, _search) {
      return axios
        .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/usager/mouvements/search`, {
          params: {
            usagerId: _search.usagerId,
            etablissementId: _search.etablissementId,
            posteAimId: _search.posteAimId == null ? null : _search.posteAimId,
          },
        })
        .then((response) => {
          commit('SET_MOUVEMENTS', response.data)
        })
    },
    async obtenirSoldesUsagerDansEtablissement(state, obtenirSoldesUsagerQuery) {
      return axios
        .get(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/usager/soldesPourEtablissement?usagerId=${obtenirSoldesUsagerQuery.usagerId}&etablissementId=${obtenirSoldesUsagerQuery.etablissementId}`
        )
        .then((response) => {
          return response.data
        })
    },

    async obtenirSoldesUsagerDansEtablissementAvantFiche(state, obtenirSoldesUsagerAvantFicheQuery) {
      return axios
        .get(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/usager/soldesPourEtablissementavantfiche?usagerId=${obtenirSoldesUsagerAvantFicheQuery.usagerId}&etablissementId=${obtenirSoldesUsagerAvantFicheQuery.etablissementId}&fichaAimId=${obtenirSoldesUsagerAvantFicheQuery.ficheAimId}`
        )
        .then((response) => {
          return response.data
        })
    },
  },
}
