<template>
  <v-tooltip right>
    <span>Filter sur le poste {{ posteComplet.libelle }}</span>
    <template v-slot:activator="{ on, attrs }">
      <v-sheet
        class="border ma-1 rounded h-100"
        :class="isSelected ? 'selected' : 'unselected'"
        outlined
        elevation="3"
        @click="$emit('click')"
        v-bind="attrs"
        v-on="on"
      >
        <v-container class="h-100 pt-1 px-1 pb-0">
          <v-row justify="start" align-content="center">
            <v-col cols="2" align-self="center">
              <v-icon class="ma-1" size="36" :color="isSelected ? 'secondary' : 'primary'">{{ icone }}</v-icon>
            </v-col>
            <v-col cols="8" align-self="center">
              <s-info-montant class="ml-2 mb-0" :montant="solde.montant">{{ solde.montant }}</s-info-montant>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </template>
  </v-tooltip>
</template>
<script>
import { mapGetters } from 'vuex'
import SInfoMontant from '@/components/global/s-info-montant.vue'

export default {
  name: 'carte-solde-usager',
  components: { SInfoMontant },
  props: {
    solde: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getIconePourPosteAIMId: 'posteAIM/getIconePourPosteAimId',
      getPosteAimById: 'posteAIM/findPosteById',
    }),
    icone() {
      return this.getIconePourPosteAIMId(this.solde.poste)
    },
    posteComplet() {
      return this.getPosteAimById(this.solde.poste)
    },
  },
}
</script>

<style scoped>
.selected {
  border-bottom: 5px solid var(--v-primary-base);
}
.unselected {
  border-bottom: 5px solid white;
}
</style>
