<script setup>
import { ref } from 'vue'
import MouvementListItem from '@/components/usager/finances/mouvement-list-item.vue'

const mvt = ref({
  montantMensuel: 32,
  dateApplication: '2024-11-25',
  posteAim: {
    posteAimId: 'DETTE',
  },
  typeDeMouvement: 'CREANCE',
})
</script>

<template>
  <v-container>
    <v-list>
      <mouvement-list-item :mouvement="mvt" />
    </v-list>
  </v-container>
</template>

<style scoped></style>
