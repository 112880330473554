var render = function render(_c,_vm){return _c('v-chip',{staticClass:"conges-solde-chip",attrs:{"label":"","small":"","text-color":isNaN(_vm.props.valeur)
      ? 'grey darken-1'
      : _vm.props.valeur > 0
      ? 'success darken-4'
      : _vm.props.valeur < 0
      ? 'error darken-4'
      : 'grey darken-1',"color":isNaN(_vm.props.valeur)
      ? 'grey lighten-1'
      : _vm.props.valeur > 0
      ? 'success lighten-4'
      : _vm.props.valeur < 0
      ? 'error lighten-4'
      : 'grey lighten-1'}},[_vm._v(" "+_vm._s(_vm.props.valeur !== undefined ? _vm.props.valeur : '-')+_vm._s(_vm.props.valeur !== undefined ? _vm.props.unite : '')+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }