<template>
  <s-main-panel v-if="loaded">
    <contrat-create-dialog
      v-model="showContratCreateDialog"
      :employe="employe"
      :key="'contratCreateDialog.' + employe.employeId"
    />
    <s-presentation-utilisateur>
      <template #left>
        <conges-soldes v-model="selectedCongesSolde" :employe-id="employeId" />
      </template>
      <template #center>
        <utilisateur-avatar :size="96" editable :utilisateur="employe.utilisateur" />
        <v-col cols="12" class="py-0">
          <span class="text-h6">
            {{ employe.nomComplet }}
          </span>
        </v-col>
        <v-col cols="12" class="py-0 pb-2">
          <a class="text-body-2" :href="`mailto:${email}`">
            {{ email }}
          </a>
        </v-col>
        <v-col cols="12" class="py-0">
          <employe-update-dialog :employe="employe" :key="'employeUpdateDialog.' + employe.employeId" />
          <v-menu v-model="menuVisible" close-on-content-click absolute offset-y>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2" small fab dark color="white" v-on="on">
                <v-icon color="success"> fa-plus </v-icon>
              </v-btn>
            </template>
            <v-list class="py-0">
              <v-list-item @click="showContratCreateDialog = true">
                <v-list-item-icon>
                  <v-icon color="info">fa-file-contract</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Ajouter un contrat</v-list-item-title>
              </v-list-item>
              <conges-create-dialog mode="list" :employe="employe" :key="'congesCreateDialog.' + employe.employeId" />
              <conges-credit-create-dialog
                mode="list"
                :employe="employe"
                :key="'congesCreditCreateDialog.' + employe.employeId"
              />
              <conges-debit-create-dialog
                mode="list"
                :employe="employe"
                :key="'congesDebitCreateDialog.' + employe.employeId"
              />
              <heures-credit-create-dialog :employe="employe" :key="'HeureCreditCreateDialog.' + employe.employeId" />
              <heures-debit-create-dialog :employe="employe" :key="'HeureDebitCreateDialog.' + employe.employeId" />
            </v-list>
          </v-menu>
        </v-col>
      </template>
      <template #right>
        <contrat-card v-if="contrat" :contrat="contrat" />
        <v-btn
          v-if="employe.contrats.length > 0"
          color="primary"
          class="mt-1"
          :to="{ name: 'EmployeDetailContrat', params: { employeId: employe.employeId } }"
          plain
        >
          Voir l'historique
        </v-btn>
        <div v-else class="d-flex flex-column align-center">
          <v-icon color="warning"> mdi-alert </v-icon>
          <p>Cet employé n'a pas encore de contrat</p>
          <v-btn color="success" dark @click="showContratCreateDialog = true">
            <v-icon class="mr-2" small>fa-plus</v-icon>
            Ajouter un contrat
          </v-btn>
        </div>
      </template>
    </s-presentation-utilisateur>
    <v-card class="ma-3 fixed-height" flat>
      <perfect-scrollbar class="scrollable">
        <router-view />
      </perfect-scrollbar>
    </v-card>
  </s-main-panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import utilisateurAvatar from '../../components/utilisateur/utilisateur-avatar'
import contratCard from '../../components/contrat/contrat-card'
import CongesSoldes from '../../components/employe/employe-solde-list'
import CongesCreateDialog from '../../components/conges/conges-create-dialog'
import CongesCreditCreateDialog from '../../components/conges/credit/conges-credit-create-dialog'
import HeuresCreditCreateDialog from '../../components/employe/soldeDeRecuperation/credit/heures-credit-create-dialog'
import ContratCreateDialog from '../../components/contrat/contrat-create-dialog'
import EmployeUpdateDialog from '../../components/employe/employe-update-dialog'
import SMainPanel from '../../components/global/s-main-panel.vue'
import CongesDebitCreateDialog from '@/components/conges/debit/conges-debit-create-dialog.vue'
import HeuresDebitCreateDialog from '@/components/employe/soldeDeRecuperation/debit/heures-debit-create-dialog.vue'
import SPresentationUtilisateur from '@/components/global/s-presentation-identite.vue'

export default {
  name: 'employe-detail',
  components: {
    SPresentationUtilisateur,
    HeuresDebitCreateDialog,
    CongesDebitCreateDialog,
    SMainPanel,
    EmployeUpdateDialog,
    ContratCreateDialog,
    utilisateurAvatar,
    contratCard,
    CongesSoldes,
    CongesCreateDialog,
    CongesCreditCreateDialog,
    HeuresCreditCreateDialog,
  },
  data() {
    return {
      avatar: undefined,
      showContratCreateDialog: false,
      selectedCongesSolde: undefined,
      menuVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      getEmployeById: 'employe/getEmployeById',
      getMembresByEmployeId: 'membre/getMembresByEmployeId',
    }),
    loaded() {
      return this.employe && this.employe.utilisateur && this.membres
    },
    employeId() {
      return parseInt(this.$route.params.employeId, 10)
    },
    selectedCongesTypeId() {
      return parseInt(this.$route.params.congesTypeId, 10)
    },
    employe() {
      return this.getEmployeById(this.employeId)
    },
    email() {
      if (this.employe.emailPro) {
        return this.employe.emailPro
      } else {
        return this.employe.utilisateur.email
      }
    },
    contrat() {
      return this.employe.contrats[0]
    },
    membres() {
      return this.getMembresByEmployeId(this.employe.employeId)
    },
  },
  created() {
    if (this.selectedCongesTypeId) {
      this.selectedCongesSolde = { congesTypeId: this.selectedCongesTypeId, employeId: this.employeId }
    }
  },
  watch: {
    selectedCongesSolde() {
      if (this.selectedCongesSolde.congesTypeId >= 0) {
        this.$router.push({
          name: 'EmployeDetailsCongesSolde',
          params: { congesTypeId: this.selectedCongesSolde.congesTypeId },
        })
      } else {
        this.$router.push({
          name: 'RecuperationDetails',
          params: { congesTypeId: this.selectedCongesSolde.congesTypeId },
        })
      }
    },
  },
  methods: {
    ...mapActions({
      deleteEmploye: 'employe/deleteEmploye',
    }),
  },
}
</script>

<style scoped>
.fixed-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.scrollable {
  display: flex;
  flex-direction: column;
}
</style>
