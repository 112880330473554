<script setup>
import SToolbar from '@/components/global/s-toolbar.vue'
</script>

<template>
  <v-dialog v-model="showDialog" persistent max-width="800px">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="primary">
        <v-icon class="pr-2">mdi-frequently-asked-questions</v-icon>
        Demander la validation de la fiche
      </v-btn>
    </template>
    <v-card v-if="showDialog">
      <s-toolbar text="demander validation fiche AIM" create @close="close" />
      <v-card-text>
        <v-textarea label="Commantaire" v-model="command.commentaire"> </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey darken-1" text @click="close">Annuler</v-btn>
        <v-btn color="success" @click="envoyerDemandeVAlidationFicheCommand"> Envoyer la demande </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    ficheAimId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      command: {},
      showDialog: false,
    }
  },
  methods: {
    ...mapActions({
      demanderValidateionFicheAIM: 'demande/createDemandeValidationFicheAIM',
    }),
    initialize() {
      this.command = {
        ficheAIMId: this.ficheAimId,
        commentaire: '',
      }
    },
    close() {
      this.initialize()
      this.showDialog = false
    },
    envoyerDemandeVAlidationFicheCommand() {
      this.demanderValidateionFicheAIM(this.command)
      this.close()
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped></style>
