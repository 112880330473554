import axios from 'axios'

export default {
  namespaced: true,
  state: {
    accueils: [],
  },
  mutations: {
    ADD_ACCUEIL(state, _accueil) {
      state.accueils.push(_accueil)
    },
    SET_ACCUEILS(state, _accueils) {
      state.accueils = _accueils
    },
  },
  getters: {
    getAccueilByUsagerId: (state) => (_usagerId) => {
      return state.accueils.filter((accueil) => accueil.usagerAccueilli.usagerId === _usagerId)
    },
    getDernierAccueilByUsagerId: (state) => (_usagerId) => {
      let accueilsUsager = state.accueils.filter((accueil) => accueil.usagerAccueilli.usagerId === _usagerId)
      if (accueilsUsager.length > 0) {
        return accueilsUsager.reduce((p, v) => (p.dateDeDebutDAccueil > v.dateDeDebutDAccueil ? p : v))
      }
      return undefined
    },
  },
  actions: {
    async fetchAccueilsByUsagerId({ commit }, usagerId) {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/accueil/usager/` + usagerId).then((response) => {
        commit('SET_ACCUEILS', response.data)
      })
    },
    async accueillirUnUsagers({ commit }, _accueillirUnUsagerCommand) {
      return await axios
        .post(`${process.env.VUE_APP_URL_BACK_SERENITY}/accueil`, _accueillirUnUsagerCommand, {
          validateStatus: (status) => status === 200,
        })
        .catch((error) => {
          console.log('error from axios')
          return {
            error: error,
            estReussi: false,
          }
        })
        .then((response) => {
          commit('ADD_ACCUEIL', response)
          return {
            accueil: response.data,
            estReussi: response.status === 200,
          }
        })
    },
  },
}
