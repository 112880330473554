<template>
  <v-form ref="form" v-model="formValid">
    <v-card-text>
      <v-container class="ma-0">
        <v-row dense>
          <v-col cols="6">
            <v-text-field label="Nom du service*" v-model="service.nom" :rules="[(v) => !!v]" required />
          </v-col>
          <v-col cols="6">
            <employe-autocomplete v-model="service.chefDeService" label="Chef(fe) de service" required />
          </v-col>
          <template v-if="service.serviceId > 0">
            <v-col cols="12">
              <v-switch
                v-model="service.heuresSurJoursFeriesAutomatiques"
                label="Heures sur jours fériés"
                inset
                persistent-hint
                :hint="hintHeuresSurJoursFeries"
              />
            </v-col>
            <v-col cols="12" v-if="isInRole('DIRECTEUR_' + serviceId)">
              <v-switch
                v-model="service.validationAutonome"
                label="Validation autonome"
                inset
                persistent-hint
                :hint="hintValidationAutonome"
              />
            </v-col>
          </template>
        </v-row>
      </v-container>
      <small>*information obligatoire</small>
    </v-card-text>
  </v-form>
</template>

<script>
import EmployeAutocomplete from '@/components/employe/employe-autocomplete'
import { mapGetters } from 'vuex'

export default {
  name: 'service-form',
  components: { EmployeAutocomplete },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formValid: false,
      service: undefined,
    }
  },
  computed: {
    ...mapGetters({
      etablissement: 'application/getSelectedEtablissement',
      isInRole: 'security/isInRole',
    }),
  },
  created() {
    this.service = this.value
  },
  watch: {
    value() {
      this.service = this.value
    },
    formValid() {
      this.$emit('updateFormValidity', this.formValid)
    },
    formService() {
      this.$emit('input', this.service)
    },
  },
}
</script>

<style scoped></style>
