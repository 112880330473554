<script setup>
import SInfoMontant from '@/components/global/s-info-montant.vue'

defineEmits(['del', 'update'])
</script>

<template>
  <tr class="ml-2">
    <td class="text-wrap">{{ operation.posteAIM.libelle }}</td>
    <td class="text-right montant">
      <s-info-montant v-if="etat === 'DEFAULT'" :colorisation="true" :montant="operation.montant"></s-info-montant>
      <v-text-field
        v-if="etat === 'EDITION'"
        v-model.number="tempMontant"
        type="number"
        @keydown.enter.stop="confirmEdit"
      ></v-text-field>
    </td>
    <td v-if="etat === 'DEFAULT'" class="line-action text-center">
      <v-btn small icon @click="setupEdit" color="info"><v-icon>mdi-pencil</v-icon></v-btn>
      <v-btn small icon @click="$emit('del')" color="error"><v-icon>mdi-delete</v-icon></v-btn>
    </td>
    <td v-if="etat === 'EDITION'" class="line-action text-center">
      <v-btn small icon @click="confirmEdit" color="success"><v-icon>mdi-check</v-icon></v-btn>
      <v-btn small icon @click="teardownEdit" color="error"><v-icon>mdi-close</v-icon></v-btn>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ligne-operation-ajustement',
  data() {
    return {
      etat: 'DEFAULT',
      tempMontant: undefined,
    }
  },
  props: {
    operation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      posteById: 'posteAIM/findPosteById',
    }),
  },
  methods: {
    setupEdit() {
      this.tempMontant = this.operation.montant
      this.etat = 'EDITION'
    },
    confirmEdit() {
      this.$emit('update', {
        montant: this.tempMontant,
        posteAIM: this.operation.posteAIM,
      })
      this.etat = 'DEFAULT'
    },
    teardownEdit() {
      this.etat = 'DEFAULT'
    },
  },
}
</script>

<style scoped>
.montant {
  width: 50%;
}
</style>
