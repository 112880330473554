<script setup>
import SMessageChargement from '@/components/global/s-message-chargement.vue'
import SCardWithToolbar from '@/components/global/s-card-with-toolbar.vue'
import MouvementListItem from '@/components/usager/finances/mouvement-list-item.vue'
</script>

<template>
  <s-card-with-toolbar class="fixed-height" :elevation="0">
    <template #titre>
      <v-toolbar-title class="text-h6">Versements & Acquis</v-toolbar-title>
    </template>
    <template #actions v-if="selectedPosteAimId !== null && selectedPosteAimId !== ''">
      <v-chip color="secondary">
        <v-icon small>mdi-filter</v-icon>
        <v-icon small>{{ iconPosteAimFilter }}</v-icon>
      </v-chip>
    </template>
    <v-container class="fixed-height pa-0">
      <perfect-scrollbar v-if="!loading">
        <v-simple-table v-for="(mensualite, id) in mouvements" :key="id">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" colspan="3">
                  <label>{{ getMonthLabel(mensualite.mois) + ' ' + mensualite.annee }}</label>
                </th>
              </tr>
            </thead>
            <mouvement-list-item
              v-for="(mouvement, id) in mensualite.mouvements"
              :key="id"
              :mouvement="mouvement"
            ></mouvement-list-item>
          </template>
        </v-simple-table>
      </perfect-scrollbar>
      <s-message-chargement class="ma-6" v-else> Chargement des mouvements </s-message-chargement>
    </v-container>
  </s-card-with-toolbar>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'table-mouvements-usager',
  props: {
    usagerId: {
      type: Number,
      required: true,
    },
    selectedPosteAimId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      getFicheAimByUsagerId: 'ficheAIM/getFicheAimByUsagerId',
      getSelectableEtablissements: 'application/getSelectedEtablissement',
      mouvements: 'usager/getMouvements',
      getMonthLabel: 'calendrier/getMonthLabel',
      getIconePourPosteAimId: 'posteAIM/getIconePourPosteAimId',
    }),
    search() {
      return {
        usagerId: this.usagerId,
        etablissementId: this.getSelectableEtablissements.etablissementId,
        posteAimId: this.selectedPosteAimId,
      }
    },
    iconPosteAimFilter() {
      return this.getIconePourPosteAimId(this.selectedPosteAimId)
    },
  },
  watch: {
    selectedPosteAimId() {
      this.loading = true
      this.fetchMouvementsBySearch(this.search).then(() => (this.loading = false))
    },
  },
  methods: {
    ...mapActions({
      fetchMouvementsBySearch: 'usager/fetchMouvementsBySearch',
    }),
  },
  mounted() {
    this.fetchMouvementsBySearch(this.search).then(() => (this.loading = false))
  },
}
</script>
<style scoped>
.fixed-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

th {
  height: 32px !important;
}
</style>
