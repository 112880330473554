<template>
  <v-list flat class="pa-0">
    <v-list-group v-for="(date, index) in Object.keys(detailsParDateAPasse)" :key="index" color="primary" no-action>
      <template v-slot:activator>
        <v-list-item>
          <v-list-item-subtitle>
            <v-chip
              label
              :class="classColor(detailsParDateAPasse[date])"
              v-text="solde(detailsParDateAPasse[date]) + 'h'"
            />
          </v-list-item-subtitle>
          <v-list-item-title :class="'text-body-1 '" v-text="formatDateToDisplay(date)" />
        </v-list-item>
      </template>
      <heures-credit-list-item v-for="credit in getCreditsByDate(date)" :key="credit.heureCreditId" :credit="credit" />
      <ecart-planning-modele-list-item
        v-for="ecart in getEcartsByDate(date)"
        :key="ecart.creneauTriId"
        :ecart="ecart"
      />
    </v-list-group>
  </v-list>
</template>

<script>
import EcartPlanningModeleListItem from './ecart-planning-modele-list-item.vue'
import HeuresCreditListItem from './heures-credit-list-item.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'detail-solde-de-recuperation-a-venir-list',
  components: {
    EcartPlanningModeleListItem,
    HeuresCreditListItem,
  },
  props: {
    detailsParDateAPasse: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      formatDateToDisplay: 'calendrier/formatDateToDisplay',
    }),
  },
  methods: {
    getEcartsByDate(_date) {
      return this.detailsParDateAPasse[_date].filter((r) => r.type !== 'HeureCredit')
    },
    getCreditsByDate(_date) {
      return this.detailsParDateAPasse[_date].filter((r) => r.type === 'HeureCredit')
    },
    solde(_creneaux) {
      let solde = 0

      _creneaux.forEach((c) => {
        solde += c.soldeEnMinute / 60
      })

      return Math.round(solde * 100) / 100
    },
    color(_soldeEnMinute) {
      if (_soldeEnMinute > 0) {
        return 'success'
      } else {
        return 'error'
      }
    },
    classColor(_creneau) {
      return `${this.color(this.solde(_creneau))} lighten-4 ` + this.textColor(this.solde(_creneau))
    },
    textColor(_soldeEnMinute) {
      return `${this.color(_soldeEnMinute)}--text text--darken-4`
    },
  },
}
</script>

<style scoped></style>
