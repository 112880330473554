<script setup>
import SMainPanel from '@/components/global/s-main-panel.vue'
import SPresentationUtilisateur from '@/components/global/s-presentation-identite.vue'
import CarteAccueilUsager from '@/components/usager/accueil/carte-accueils-usager.vue'
</script>

<template>
  <s-main-panel>
    <s-presentation-utilisateur class="ma-3 mb-0" v-if="usager !== undefined">
      <template #left>
        <v-list dense nav class="text-body-1 py-0">
          <v-list-item-group color="primary">
            <v-list-item :to="{ name: 'finances-usager' }">
              <v-list-item-content class="py-1">
                <v-list-item-title justify="center">Finances usager</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
      <template #center>
        <v-avatar size="96"></v-avatar>
        <v-col cols="12" class="py-0">
          <span class="text-h6"> {{ usager.nom }} {{ usager.prenom }} </span>
        </v-col>
      </template>
      <template #right>
        <carte-accueil-usager />
      </template>
    </s-presentation-utilisateur>
    <v-container class="mt-0 fixed-height" flat>
      <router-view />
    </v-container>
  </s-main-panel>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'usager-detail',
  computed: {
    ...mapGetters({
      getUsagerById: 'usager/getUsagerById',
    }),
    usagerId() {
      return parseInt(this.$route.params.usagerId)
    },
    usager() {
      return this.getUsagerById(this.usagerId)
    },
  },
}
</script>

<style scoped>
.router-view-row {
  bottom: 10px;
}
</style>
