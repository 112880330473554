<script setup>
import SMainPanel from '@/components/global/s-main-panel.vue'
import SStepper from '@/components/global/step-card-pannel/s-stepper.vue'

const props = defineProps({
  titrePremierPanel: {
    type: String,
    default: 'First panel',
  },
  titreSecondPanel: {
    type: String,
    default: 'Second panel',
  },
  sousTitre: {
    type: String,
    default: '',
  },
})
</script>

<template>
  <s-main-panel>
    <v-container class="h-100" fluid>
      <s-stepper
        class="breadcrumb pb-6"
        :titre="etape === 1 ? props.titrePremierPanel : props.titreSecondPanel"
        :sous-titre="props.sousTitre"
      >
        <template #prev-btn>
          <v-btn @click="stepPrev" color="secondary" :disabled="etape === 1">
            <v-icon class="pr-2">mdi-chevron-left</v-icon>
            Voir les ajustements
          </v-btn>
        </template>
        <template #next-btn>
          <v-btn @click="stepNext" color="secondary" :disabled="etape === 2">
            Voir les versements
            <v-icon class="pr-2">mdi-chevron-right</v-icon>
          </v-btn>
        </template>
      </s-stepper>
      <v-row class="main-content-row">
        <transition name="fade">
          <v-col class="h-100" cols="6" v-show="panels[0]">
            <slot name="first-panel" :onStepNext="stepNext"></slot>
          </v-col>
        </transition>
        <v-col class="h-100" cols="6" :class="slideMiddlePanelCss">
          <slot name="second-panel"></slot>
        </v-col>
        <transition name="fade">
          <v-col class="h-100" cols="6" v-show="panels[2]">
            <slot name="third-panel" :onStepPrev="stepPrev"></slot>
          </v-col>
        </transition>
      </v-row>
    </v-container>
  </s-main-panel>
</template>

<script>
export default {
  name: 's-step-card-panels',
  computed: {
    ficheAimId() {
      return this.$route.params.ficheAimId
    },
    slideMiddlePanelCss() {
      if (this.etape === 2 && !this.panels[0] && this.inAnimation) {
        return 'slide-left'
      } else if (this.etape === 1 && !this.panels[2] && this.inAnimation) {
        return 'slide-right'
      }
      return ''
    },
  },
  data() {
    return {
      etape: 1,
      panels: [true, true, false],
      inAnimation: false,
    }
  },
  methods: {
    stepNext() {
      this.inAnimation = true
      this.etape++
      this.disablePanel(this.etape - 1)
      setTimeout(() => this.enablePanel(this.etape + 1), 500)
      setTimeout(() => this.endAnimation(), 500)
    },
    stepPrev() {
      this.inAnimation = true
      this.etape--
      this.disablePanel(this.etape + 2)
      setTimeout(() => this.enablePanel(this.etape), 500)
      setTimeout(() => this.endAnimation(), 500)
    },
    enablePanel(pannelNumber) {
      this.panels[pannelNumber - 1] = true
    },
    disablePanel(pannelNumber) {
      this.panels[pannelNumber - 1] = false
    },
    endAnimation() {
      this.inAnimation = false
    },
  },
}
</script>

<style scoped>
.fixed-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.h-100 {
  height: 100% !important;
}
.main-content-row {
  height: 94% !important;
}
.fade-enter-active {
  transition: opacity 0.3s;
}
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-left {
  animation: slide-left 0.25s;
  animation-delay: 0.25s;
}
@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slide-right {
  animation: slide-right 0.25s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-delay: 0.25s;
}
@keyframes slide-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
