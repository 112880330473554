<template>
  <p>
    <slot></slot>
    <v-progress-circular class="ml-4" size="24" color="secondary" indeterminate />
  </p>
</template>
<script>
export default {
  name: 's-message-chargement',
}
</script>
