<script setup>
import SDatePicker from '@/components/global/s-date-picker.vue'
</script>

<template>
  <div class="text-center">
    <v-btn max-height="35px" color="green" tile block dark @click="setupFormulaire"> accueillir un usager </v-btn>
    <v-dialog v-model="dialog" width="720">
      <v-form @submit.prevent="submitFormulaire">
        <v-card :loading="loading">
          <v-card-title class="text-h5 primary lighten-2"> Accueillir un usager </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="2" sm="6" md="6" lg="2">
                <v-combobox
                  v-model="accueillirUnUsagerCommand.genre"
                  :items="[
                    { libelle: 'Mr', valeur: 'MASCULIN' },
                    { libelle: 'Mme', valeur: 'FEMININ' },
                  ]"
                  item-text="libelle"
                  label="M*"
                  hide-no-data
                  required
                ></v-combobox>
              </v-col>
              <v-col cols="5" sm="12" md="12" lg="5">
                <v-text-field label="Prenom*" v-model="accueillirUnUsagerCommand.prenom" />
              </v-col>
              <v-col cols="5" sm="12" md="12" lg="5">
                <v-text-field label="Nom*" v-model="accueillirUnUsagerCommand.nom" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-combobox
                  v-model="accueillirUnUsagerCommand.serviceDAccueilId"
                  :loading="!loadedServicies"
                  :disabled="!loadedServicies"
                  :items="services"
                  item-text="nom"
                  label="Services d'accueil*"
                  hide-no-data
                  required
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="12" md="6">
                <s-date-picker
                  v-model="accueillirUnUsagerCommand.dateDeDebutDAccueil"
                  label="Date de début de l'accueil"
                  required
                />
              </v-col>
              <v-col cols="6" sm="12" md="6">
                <s-date-picker
                  v-model="accueillirUnUsagerCommand.dateDeFinDAccueil"
                  label="Date de fin prévue de l'accueil"
                  hint="Optionnel"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="tearDownFormulaire"> Annuler </v-btn>
            <v-btn :disabled="!isValidform" color="primary" text type="submit"> Accueillir </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar v-model="errorSnackbar" :timeout="10000">
      Erreur lors de l'enregistrement de l'accueil, veuillez recharger la page, avant de réessayer
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="errorSnackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      loadedServicies: false,
      dialog: false,
      accueillirUnUsagerCommand: {},
      errorSnackbar: false,
    }
  },
  computed: {
    ...mapGetters({
      etablissementCourant: 'application/getSelectedEtablissement',
      services: 'service/getServices',
    }),
    isValidform() {
      return (
        this.loadedServicies &&
        this.accueillirUnUsagerCommand.prenom !== '' &&
        this.accueillirUnUsagerCommand.nom !== '' &&
        this.accueillirUnUsagerCommand.genre !== '' &&
        this.accueillirUnUsagerCommand.serviceDAccueilId !== undefined &&
        this.accueillirUnUsagerCommand.dateDeDebutDAccueil !== undefined
      )
    },
  },
  methods: {
    ...mapActions({
      accueillirUnUsager: 'accueil/accueillirUnUsagers',
      fetchUsagers: 'usager/fetchUsagers',
      fetchServicesByEtablissement: 'service/fetchServicesByEtablissement',
    }),
    setupFormulaire() {
      this.accueillirUnUsagerCommand = {
        nom: '',
        prenom: '',
        genre: '',
        serviceDAccueilId: undefined,
        dateDeDebutDAccueil: undefined,
        dateDeFinDAccueil: undefined,
      }
      this.dialog = true
    },
    tearDownFormulaire() {
      this.accueillirUnUsagerCommand = {}
      this.dialog = false
    },
    formatCommand() {
      this.accueillirUnUsagerCommand.serviceDAccueilId = this.accueillirUnUsagerCommand.serviceDAccueilId.serviceId
      this.accueillirUnUsagerCommand.genre = this.accueillirUnUsagerCommand.genre.valeur
    },
    onSuccess(usagerId) {
      this.fetchUsagers().then(() => {
        this.$router.push({
          name: 'usager',
          params: { usagerId: usagerId },
        })
      })
    },
    onError() {
      console.log('On error')
      this.errorSnackbar = true
    },
    submitFormulaire() {
      this.loading = true
      this.formatCommand()
      this.accueillirUnUsager(this.accueillirUnUsagerCommand).then((value) => {
        this.loading = false
        this.tearDownFormulaire()
        if (value.estReussi) this.onSuccess(value.accueil.usagerAccueilli.usagerId)
        else this.onError()
      })
    },
  },
  created() {
    this.fetchServicesByEtablissement(this.etablissementCourant).then(() => (this.loadedServicies = true))
  },
}
</script>

<style scoped></style>
