<script setup>
import FicheAimUsagerListItem from '@/components/fiche-aim/fiche-aim-usager-list-item.vue'
import SMessageChargement from '@/components/global/s-message-chargement.vue'
import SCardWithToolbar from '@/components/global/s-card-with-toolbar.vue'
</script>

<template>
  <s-card-with-toolbar class="fixed-height" :elevation="0">
    <template #titre>
      <v-toolbar-title class="text-h6"> Fiches d'Aide Individuelles Mensuelles </v-toolbar-title>
    </template>
    <template #actions v-if="selectedPosteAimId !== null && selectedPosteAimId !== ''">
      <v-chip color="secondary">
        <v-icon small>mdi-filter</v-icon>
        <v-icon small>{{ iconPosteAimFilter }}</v-icon>
      </v-chip>
    </template>
    <v-container class="fixed-height pa-0">
      <perfect-scrollbar v-if="!loading && fichesAimUsager.length > 0">
        <v-list two-line class="pa-0">
          <fiche-aim-usager-list-item
            v-for="ficheAim in fichesAimUsager"
            :key="ficheAim.ficheAimId"
            :fiche-aim="ficheAim"
          ></fiche-aim-usager-list-item>
        </v-list>
      </perfect-scrollbar>
      <p class="ma-6" v-else-if="!loading">
        <v-icon color="primary" class="ma-1 mb-2">mdi-circle-off-outline</v-icon>
        Aucune fiche
      </p>
      <s-message-chargement class="ma-6" v-else> Chargement des fiches </s-message-chargement>
    </v-container>
  </s-card-with-toolbar>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'liste-fiche-aim-usager',
  props: {
    usagerId: {
      type: Number,
      required: true,
    },
    selectedPosteAimId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  watch: {
    selectedPosteAimId() {
      this.loading = true
      this.fetchFicheAimBySearch(this.searchAim).then(() => (this.loading = false))
    },
  },
  computed: {
    ...mapGetters({
      getFicheAimByUsagerId: 'ficheAIM/getFicheAimByUsagerId',
      selectedEtablissement: 'application/getSelectedEtablissement',
      getIconePourPosteAimId: 'posteAIM/getIconePourPosteAimId',
    }),
    iconPosteAimFilter() {
      return this.getIconePourPosteAimId(this.selectedPosteAimId)
    },
    searchAim() {
      return {
        usagerId: this.usagerId,
        etablissementId: this.selectedEtablissement.etablissementId,
        posteAimId: this.selectedPosteAimId,
      }
    },
    fichesAimUsager() {
      return this.getFicheAimByUsagerId(this.usagerId).sort((a, b) => {
        if (a.annee !== b.annee) return a.annee < b.annee
        return a.mois < b.mois
      })
    },
  },
  methods: {
    ...mapActions({
      fetchFicheAimBySearch: 'ficheAIM/fetchFicheAimBySearch',
    }),
  },
  mounted() {
    this.fetchFicheAimBySearch(this.searchAim).then(() => (this.loading = false))
  },
}
</script>
<style scoped>
.fixed-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}
</style>
