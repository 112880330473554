<script setup>
import SInfoMontant from '@/components/global/s-info-montant.vue'
import LigneOperationAjustement from '@/components/fiche-aim/preparationFicheAim/ajustement/ligne-operation-ajustement.vue'
import AjouterOperationAjustementForm from '@/components/fiche-aim/preparationFicheAim/ajustement/ajouter-operation-ajustement-form.vue'

defineProps({
  ajustement: {
    required: true,
    type: Object,
    default: () => ({
      operations: [],
    }),
  },
})

defineEmits(['update:ajustement'])
</script>

<template>
  <v-form>
    <h4 class="text-decoration-none">Montants à ajuster</h4>
    <v-chip class="ma-4" v-if="nbOperations === 0">
      <v-icon class="pr-2">mdi-alert-circle</v-icon>
      Aucune opération pour cet ajustement
    </v-chip>
    <v-simple-table height="350px" class="table" fixed-header>
      <thead>
        <tr>
          <th>Poste</th>
          <th class="text-right">montant</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ajouter-operation-ajustement-form
          :postes-non-definits="postesNonDefinits"
          @add="ajouterOperation"
        ></ajouter-operation-ajustement-form>
        <template v-if="loading">
          <v-progress-circular indeterminate />
          <label>Chargement en cours</label>
        </template>
        <ligne-operation-ajustement
          v-else
          v-for="(op, i) in Object.values(ajustement.operations)"
          :key="i"
          :operation="op"
          @update="modifierOperationAjustement"
          @del="supprimerOperation(op.posteAIM.posteAIMId)"
        />
        <tr>
          <th class="text-right"></th>
          <th class="text-right">Total : <s-info-montant :montant="totalAjustement"></s-info-montant></th>
        </tr>
      </tbody>
    </v-simple-table>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      postesDisponibles: 'posteAIM/getPostes',
    }),
    postesNonDefinits() {
      return this.postesDisponibles.filter((posteAIM) => !this.existeDansAjustement(posteAIM))
    },
    totalAjustement() {
      let sum = 0
      for (var i = 0; i < this.nbOperations; i++) {
        sum += this.ops[i].montant
      }
      return sum
    },
    ops() {
      return Object.values(this.ajustement.operations)
    },
    nbOperations() {
      return this.ops.length
    },
  },
  methods: {
    ...mapActions({
      fetchPostes: 'posteAIM/fetchPostes',
    }),
    existeDansAjustement(posteAIM) {
      return this.ajustement.operations.some((op) => op.posteAIM.posteAIMId === posteAIM.posteAIMId)
    },
    ajouterOperation(operation) {
      this.ajustement.operations.push(operation)
    },
    modifierOperationAjustement(op) {
      this.supprimerOperation(op.posteAIM.posteAIMId)
      this.ajouterOperation(op)
    },
    supprimerOperation(posteAIMId) {
      this.ajustement.operations.splice(
        this.ajustement.operations.findIndex((value) => value.posteAIM.posteAIMId === posteAIMId),
        1
      )
    },
  },
  mounted() {
    this.fetchPostes().then(() => (this.loading = false))
  },
}
</script>

<style scoped></style>
