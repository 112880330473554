<script setup>
import SCardWithToolbar from '@/components/global/s-card-with-toolbar.vue'
import StatutFicheAim from '@/components/fiche-aim/status-fiche-aim.vue'
import FicheAimUsagerExpensionPanel from '@/components/fiche-aim/ligne/ligne-aim-expension-panel.vue'
import ficheAIM from '../../../store/modules/aim/ficheAIM'
import DialogDemandeValidationFicheAim from '@/components/fiche-aim/preparationFicheAim/dialog-demande-validation-fiche-aim.vue'
</script>

<template>
  <s-card-with-toolbar is-extented>
    <template v-if="!!ficheAIM" #titre>
      Fiche d'Aide Individuelle Mensuelle - {{ ficheAim.mois }}/{{ ficheAim.annee }}
    </template>
    <template #top>
      <v-container class="pr-8">
        <v-row no-gutters align="center">
          <v-col cols="4" align-self="center">
            <span>Poste</span>
          </v-col>
          <v-col cols="8" class="text--secondary">
            <v-row no-gutters style="width: 100%" align="center">
              <v-col class="text-right pr-6" cols="4">
                <span>Solde début de mois</span>
              </v-col>
              <v-col class="text-right pr-6" cols="4">
                <span>Acquis du mois</span>
              </v-col>
              <v-col class="text-right pr-6" cols="4">
                <span>Après ajustements</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template #actions><statut-fiche-aim :statut-fiche="ficheAim.statut" /></template>
    <template #default>
      <perfect-scrollbar class="h-100" v-if="!loading">
        <v-expansion-panels @change="reinitializerAjustementSelectionne" v-model="lignesActives" multiple>
          <fiche-aim-usager-expension-panel
            v-for="ligne in ficheAim.lignes"
            :key="ligne.ligneFicheId"
            :ligne-fiche-aim="ligne"
            :soldes="soldesUsager"
          />
        </v-expansion-panels>
      </perfect-scrollbar>
    </template>
    <template #bottom>
      <v-card>
        <v-card-actions>
          <v-spacer />
          <dialog-demande-validation-fiche-aim
            v-if="ficheAim.statut === 'EN_PREPARATION'"
            :fiche-aim-id="ficheAim.ficheAIMId"
          />
        </v-card-actions>
      </v-card>
    </template>
  </s-card-with-toolbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'rendu-fiche-aim',
  props: {
    ficheAim: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lignesActives: [],
      soldesUsager: [],
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      estAjustementConcerneParAjustementSelectionne:
        'renduAIM_IHM_helper/estAjustementConcerneParAjustementSelectionne',
      estSelectionne: 'renduAIM_IHM_helper/estSelectionne',
    }),
    indexLignesConcernesParSelection() {
      let indexLignes = []
      this.ficheAim.lignes.forEach((ligne, i) => {
        if (ligne.operations.some((op) => this.estAjustementConcerneParAjustementSelectionne(op.ajustementId))) {
          indexLignes.push(i)
        }
      })
      return indexLignes
    },
  },
  methods: {
    ...mapActions({
      reinitializerAjustementSelectionne: 'renduAIM_IHM_helper/reinitializerAjustementSelectionne',
      obtenirSoldesUsagerDansEtablissementAvantFiche: 'usager/obtenirSoldesUsagerDansEtablissementAvantFiche',
    }),
  },
  watch: {
    indexLignesConcernesParSelection: function (indexLignes) {
      if (this.estSelectionne) this.lignesActives = indexLignes
    },
  },
  mounted() {
    this.obtenirSoldesUsagerDansEtablissementAvantFiche({
      usagerId: this.ficheAim.usager.usagerId,
      etablissementId: this.ficheAim.etablissement.etablissementId,
      ficheAimId: this.ficheAim.ficheAIMId,
    }).then((value) => {
      this.soldesUsager = value
      this.loading = false
    })
  },
}
</script>

<style>
.h-100 {
  height: 100% !important;
}
</style>
