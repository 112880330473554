import axios from 'axios'

export default {
  namespaced: true,
  state: {
    demandes: [],
  },
  mutations: {
    SET_DEMANDES(state, _demandes) {
      _demandes.forEach((demande) => {
        if (!state.demandes.some((d) => d.demandeId === demande.demandeId)) {
          this.commit('demande/ADD_DEMANDE', demande)
        } else {
          this.commit('demande/UPDATE_DEMANDE', demande)
        }
      })
    },
    ADD_DEMANDE(state, _demandeToAdd) {
      state.demandes.push(_demandeToAdd)
    },
    UPDATE_DEMANDE(state, _demandeToUpdate) {
      const indexToUpdate = state.demandes.findIndex((demande) => demande.demandeId === _demandeToUpdate.demandeId)

      if (indexToUpdate >= 0) {
        state.demandes.splice(indexToUpdate, 1, _demandeToUpdate)
      }
    },
    REMOVE_DEMANDE(state, _demandeToRemoveId) {
      state.demandes.splice(
        state.demandes.findIndex((demande) => demande.demandeId === _demandeToRemoveId),
        1
      )
    },
  },
  getters: {
    getDemandes(state) {
      return state.demandes.sort(function (sA, sB) {
        return sA.demandeId - sB.demandeId
      })
    },
    getDemandesByStatusId: (state) => (_demandeStatutId) => {
      return state.demandes.filter((d) => d.statut.demandeStatutId === _demandeStatutId)
    },
    getDemandesHistorique: (state, getters, rootState, rootGetters) => () => {
      return state.demandes.filter(
        (d) =>
          d.statut.demandeStatutId > 1 &&
          d.employe &&
          d.employe.utilisateur.utilisateurId === rootGetters['security/getUtilisateur'].utilisateurId
      )
    },
    getDemandesAValider: (state, getters, rootState, rootGetters) => () => {
      return getters
        .getDemandesByStatusId(1)
        .filter(
          (d) =>
            d.employe && d.employe.utilisateur.utilisateurId !== rootGetters['security/getUtilisateur'].utilisateurId
        )
    },
    getDemandesEnAttente: (state, getters, rootState, rootGetters) => () => {
      return getters
        .getDemandesByStatusId(1)
        .filter(
          (d) =>
            d.employe && d.employe.utilisateur.utilisateurId === rootGetters['security/getUtilisateur'].utilisateurId
        )
    },
    getDemandeById: (state) => (_demandeId) => {
      return state.demandes.find((demande) => demande.demandeId === _demandeId)
    },
  },
  actions: {
    fetchDemandes({ commit }) {
      axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/demande/utilisateur`).then((response) => {
        commit('SET_DEMANDES', response.data)
        axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/demande/enAttente`).then((response) => {
          commit('SET_DEMANDES', response.data)
        })
      })
    },
    createDemandeConges({ commit }, _demandeToCreate) {
      return axios
        .post(`${process.env.VUE_APP_URL_BACK_SERENITY}/demande/conges`, _demandeToCreate)
        .then((response) => {
          commit('ADD_DEMANDE', response.data)
        })
    },
    evaluerDemandeHeuresSupp(context, _demandeAEvaluer) {
      return axios.post(`${process.env.VUE_APP_URL_BACK_SERENITY}/demande/heuresSupp/evaluer`, _demandeAEvaluer)
    },
    createDemandeHeuresSupp({ commit }, _demandeToCreate) {
      return axios
        .post(`${process.env.VUE_APP_URL_BACK_SERENITY}/demande/heuresSupp`, _demandeToCreate)
        .then((response) => {
          commit('ADD_DEMANDE', response.data)
          return response.data
        })
    },
    evaluerDemandeRecuperation(context, _demandeAEvaluer) {
      return axios.post(`${process.env.VUE_APP_URL_BACK_SERENITY}/demande/recuperation/evaluer`, _demandeAEvaluer)
    },
    createDemandeRecuperation({ commit }, _demandeToCreate) {
      return axios
        .post(`${process.env.VUE_APP_URL_BACK_SERENITY}/demande/recuperation`, _demandeToCreate)
        .then((response) => {
          commit('ADD_DEMANDE', response.data)
          return response.data
        })
    },
    createDemandeValidationFicheAIM({ commit }, _creerDemandeValidationFicheAIMCommand) {
      return axios
        .post(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/demande/validation-fiche-AIM`,
          _creerDemandeValidationFicheAIMCommand
        )
        .then((response) => {
          commit('ADD_DEMANDE', response.data)
          return response.data
        })
    },
    createCommentaire({ commit }, _payload) {
      return axios
        .post(`${process.env.VUE_APP_URL_BACK_SERENITY}/demande/${_payload.demandeId}/commentaire`, _payload)
        .then((response) => {
          commit('UPDATE_DEMANDE', response.data)
        })
    },
    deleteCommentaire({ commit }, _payload) {
      return axios
        .delete(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/demande/${_payload.demandeId}/commentaire/${_payload.commentaireId}`
        )
        .then((response) => {
          commit('UPDATE_DEMANDE', response.data)
        })
    },
    validerDemande({ commit }, _payload) {
      return axios
        .put(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/demande/${_payload.demandeId}/validation/${_payload.validationId}/valider`
        )
        .then((response) => {
          commit('UPDATE_DEMANDE', response.data)
        })
    },
    refuserDemande({ commit }, _payload) {
      return axios
        .put(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/demande/${_payload.demandeId}/validation/${_payload.validationId}/refuser`
        )
        .then((response) => {
          commit('UPDATE_DEMANDE', response.data)
        })
    },
    updateDemande({ commit }, _demandeToUpdate) {
      return axios
        .put(`${process.env.VUE_APP_URL_BACK_SERENITY}/demande/${_demandeToUpdate.demandeId}`, _demandeToUpdate)
        .then((response) => {
          commit('UPDATE_DEMANDE', response.data)
        })
    },
    deleteDemande({ commit }, _demandeToDeleteId) {
      return axios.delete(`${process.env.VUE_APP_URL_BACK_SERENITY}/demande/${_demandeToDeleteId}`).then((response) => {
        if (response.data) {
          commit('REMOVE_DEMANDE', _demandeToDeleteId)
        }
      })
    },
  },
}
